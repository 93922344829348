import React, { lazy, Suspense } from "react";
import {
  ConfirmSignIn,
  Loading,
  TOTPSetup,
  withAuthenticator,
  VerifyContact
} from "aws-amplify-react";

import awsconfig from "../aws-exports";
import Amplify, { AWS } from "@aws-amplify/core";
import version from "../version.json";
import ErrorBoundary from "./ErrorBoundary.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CustomConfirmSignUp from "./CustomAuthComponents/CustomConfirmSignUp";
import SignInRouter from "./CustomAuthComponents/SignInRouter";
import CustomRequireNewPassword from "./CustomAuthComponents/CustomRequireNewPassword";
import CustomSignUp, {
  ISignUpField,
  ISignUpConfig
} from "./CustomAuthComponents/CustomSignUp";
import { BrowserRouter } from "react-router-dom";

const MyTheme = {
  button: { backgroundColor: "#78B8F0" },
  container: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center top",
    backgroundSize: window.innerHeight / 3
  },
  a: {
    color: "#0d1457"
  }
};
const AppWrapper = lazy(() => import("./AppWrapper"));
const signUpFields: ISignUpField[] = [
  {
    label: "Company/Organization name",
    key: "custom:tenantName",
    required: true,
    displayOrder: 1,
    type: "text",
    "data-testid": "CustomSignUpTenantInput"
  },
  {
    label: "Email",
    key: "username",
    required: true,
    displayOrder: 2,
    type: "email",
    placeholder: "example@acme-inc.com",
    "data-testid": "CustomSignUpEmailInput"
  },
  {
    label: "Password",
    key: "password",
    required: true,
    displayOrder: 3,
    type: "password",
    "data-testid": "CustomSignUpPasswordInput"
  },
  {
    label: "Full Name",
    key: "given_name",
    required: true,
    displayOrder: 4,
    type: "text",
    "data-testid": "CustomSignUpFullNameInput"
  }
];

const signUpConfig: ISignUpConfig = {
  hideAllDefaults: true,
  usernameAttributes: "Email",
  signUpFields: signUpFields
};

Amplify.configure(awsconfig);
AWS.config.update({ region: awsconfig.aws_cognito_region });

const LazyAppWrapper = () => {
  return (
    <Suspense fallback={<p>Loading...</p>}>
      <AppWrapper />
    </Suspense>
  );
};

const WithAuthenticator = withAuthenticator(
  LazyAppWrapper,
  true,
  [
    <SignInRouter />,
    <ConfirmSignIn />,
    <CustomRequireNewPassword />,
    <CustomSignUp signUpConfig={signUpConfig} />,
    <CustomConfirmSignUp />,
    <VerifyContact />,
    <TOTPSetup />,
    <Loading />
  ],
  signUpConfig,
  MyTheme
);

const WithRouter = () => {
  return (
    <ErrorBoundary>
      <ToastContainer hideProgressBar />

      <BrowserRouter>
        <WithAuthenticator />
        <div
          style={{
            zIndex: 200,
            position: "fixed",
            bottom: 5,
            right: 5,
            color: "gray",
            opacity: 0.5
          }}
        >
          {version.version}
        </div>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default WithRouter;
