import React from "react";
import babyCryingLogo from "../assets/images/baby-crying.jpg";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <figure
            style={{
              width: "500px",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "100px"
            }}
          >
            <img
              style={{
                width: "300px"
              }}
              src={babyCryingLogo}
              alt="HubHighUp"
            />
            <figcaption
              style={{
                width: "300px",
                textAlign: "center"
              }}
            >
              Something went wrong. Please wait few seconds and then refresh the
              page.
            </figcaption>
          </figure>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
