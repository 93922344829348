import React, { useState } from "react";
import { Form, Button, Card, Grid } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import Auth from "@aws-amplify/auth";
import { JS, ConsoleLogger as Logger } from "@aws-amplify/core";
import { toast } from "react-toastify";
import AlmostThereMessageImg from "../../assets/images/AlmostThereMessageImg.png";
import { Image } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import Logo from "../../assets/images/hubhighup-logo.png";

const logger = new Logger("SignIn");

const CustomSignIn = ({
  props
}: {
  props: {
    authData?: {
      attributes: {
        email: string;
        sub: string;
      };
    };
    authState?: string;
    onStateChange?: (state: string, data?: string) => any;
  };
}) => {
  const { register, handleSubmit } = useForm();
  const [loader, setLoader] = useState<boolean>(false);
  const history = useHistory();

  const checkContact = (user: any) => {
    if (!Auth || typeof Auth.verifiedContact !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported"
      );
    }
    Auth.verifiedContact(user).then(data => {
      if (!JS.isEmpty(data.verified)) {
        if (props.onStateChange) props.onStateChange("signedIn", user);
      } else {
        user = Object.assign(user, data);
        if (props.onStateChange) props.onStateChange("verifyContact", user);
      }
    });
  };

  const changeState = (state: any, data?: any) => {
    if (props.onStateChange) {
      props.onStateChange(state, data);
    }
  };

  const error = (err: any) => {
    console.log(err);
    toast.error(err.message);
  };

  const submitForm = async (values: any) => {
    setLoader(true);
    try {
      const user = await Auth.signIn(values.email, values.password, {
        url: window.location.protocol + "//" + window.location.host
      });
      logger.debug(user);
      if (
        user.challengeName === "SMS_MFA" ||
        user.challengeName === "SOFTWARE_TOKEN_MFA"
      ) {
        logger.debug("confirm user with " + user.challengeName);
        changeState("confirmSignIn", user);
      } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        logger.debug("require new password", user.challengeParam);
        changeState("requireNewPassword", user);
      } else if (user.challengeName === "MFA_SETUP") {
        logger.debug("TOTP setup", user.challengeParam);
        changeState("TOTPSetup", user);
      } else if (
        user.challengeName === "CUSTOM_CHALLENGE" &&
        user.challengeParam &&
        user.challengeParam.trigger === "true"
      ) {
        logger.debug("custom challenge", user.challengeParam);
        changeState("customConfirmSignIn", user);
      } else {
        checkContact(user);
      }
    } catch (err) {
      if (err.code === "UserNotConfirmedException") {
        logger.debug("the user is not confirmed");
        changeState("confirmSignUp", values.email);
      } else if (err.code === "PasswordResetRequiredException") {
        logger.debug("the user requires a new password");
        changeState("forgotPassword", values.email);
      } else {
        error(err);
      }
    } finally {
      console.log("Success");
    }
    setLoader(false);
  };
  if (
    props.authState === "signIn" ||
    props.authState === "signInConfirmation"
  ) {
    return (
      <div>
        <Grid
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            verticalAlign: "center",
            flexDirection: "column",
            flexWrap: "nowrap"
          }}
        >
          <Image
            src={Logo}
            style={{
              height: `100px`,
              width: `auto`,
              justifyContent: "flex-start",
              alignSelf: "center",
              marginTop: 30
            }}
          />
          <Grid.Column computer={6} tablet={8} mobile={14}>
            <>
              {props.authState === "signInConfirmation" && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    verticalAlign: "center",
                    justifyContent: "center",
                    zIndex: -1,
                    marginRight: 150
                  }}
                >
                  <Image
                    style={{ height: 200, marginRight: 200 }}
                    alt={"AlmostThereMessage"}
                    src={AlmostThereMessageImg}
                  />
                </div>
              )}
              <div style={{ zIndex: 1 }}>
                <Card centered fluid>
                  <Card.Content>
                    <Card.Header data-testid="CustomSignInHeader">
                      Sign in to your account
                    </Card.Header>
                  </Card.Content>
                  <Card.Content>
                    <Form onSubmit={handleSubmit(submitForm)}>
                      <Form.Field required>
                        <label>Email</label>
                        <input
                          data-testid="CustomSignInEmailInput"
                          type="text"
                          name="email"
                          style={{
                            width: "100%",
                            margin: "8px 0",
                            display: "inline-block"
                          }}
                          placeholder="Enter your email"
                          ref={register}
                          required
                        />
                      </Form.Field>
                      <Form.Field required>
                        <label>Password</label>
                        <input
                          data-testid="CustomSignInPasswordInput"
                          type="password"
                          name="password"
                          style={{
                            width: "100%",
                            margin: "8px 0",
                            display: "inline-block"
                          }}
                          placeholder="Enter your password"
                          ref={register}
                          required
                        />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start"
                          }}
                        >
                          <div style={{ marginRight: "5px", fontSize: "12px" }}>
                            Forgot your passowrd?
                          </div>
                          <div
                            data-testid="CustomSignInResetPasswordTag"
                            style={{
                              color: "#0d1457",
                              fontSize: "12px",
                              cursor: "pointer"
                            }}
                            onClick={() => {
                              if (props.onStateChange)
                                props.onStateChange("forgotPassword");
                            }}
                          >
                            Reset password
                          </div>
                        </div>
                      </Form.Field>
                      <div style={{ display: "flex" }}>
                        <Button
                          {...{ loading: loader }}
                          style={{
                            flex: 1,
                            width: "20%",
                            display: "inline-block",
                            backgroundColor: "#78B8F0",
                            color: "white",
                            marginLeft: "auto",
                            marginRight: "auto",
                            fontSize: "medium"
                          }}
                          type="submit"
                          data-testid="CustomSignInSubmitButton"
                        >
                          Sign In
                        </Button>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingTop: "10px"
                        }}
                      >
                        <div style={{ marginRight: "5px", fontSize: "14px" }}>
                          Don't have an account yet?
                        </div>
                        <div
                          data-testid="CustomSignInCreateAccountTag"
                          style={{
                            color: "#0d1457",
                            fontSize: "14px",
                            cursor: "pointer"
                          }}
                          onClick={() => {
                            if (props.onStateChange)
                              props.onStateChange("signUp");
                            history.replace("");
                          }}
                        >
                          Create account
                        </div>
                      </div>
                    </Form>
                  </Card.Content>
                </Card>
              </div>
            </>
          </Grid.Column>
        </Grid>
      </div>
    );
  } else {
    return null;
  }
};
export default CustomSignIn;
