import React, { useState } from "react";
import { ConsoleLogger as Logger } from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import { Card, Form, Button, Grid } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useLocation, useHistory } from "react-router-dom";
import { Image } from "semantic-ui-react";
import Logo from "../../assets/images/hubhighup-logo.png";

const logger = new Logger("ForgotPassword");
const queryString = require("query-string");

const CustomForgotPassword = ({
  props
}: {
  props: {
    authData?: {
      attributes: {
        email: string;
        sub: string;
      };
    };
    authState?: string;
    onStateChange?: (state: string, data?: string) => any;
  };
}) => {
  const { register, handleSubmit } = useForm();
  const [delivery, setDelivery] = useState(null);
  const [loader, setLoader] = useState<boolean>(false);
  const notify = () => toast.success("The code was sent to your e-mail");
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  let history = useHistory();

  const send = async (values: any) => {
    setLoader(true);
    const { authData } = props;
    let username;
    if (authData) username = authData.attributes.email;
    else username = values.email;
    if (!Auth || typeof Auth.forgotPassword !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported"
      );
    }
    await Auth.forgotPassword(username, {
      username: username,
      url: window.location.protocol + "//" + window.location.host
    })
      .then(data => {
        logger.debug(data);
        setDelivery(data.CodeDeliveryDetails);
        notify();
      })
      .catch(err => toast.error(err.message));

    setLoader(false);
  };

  const submit = async (values: any) => {
    setLoader(true);
    const { authData } = props;
    const { code, password } = {
      code: values.code,
      password: values.newPassword
    };
    let username;
    if (authData) username = authData.attributes.email;
    else username = values.email;
    if (!Auth || typeof Auth.forgotPasswordSubmit !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported"
      );
    }
    await Auth.forgotPasswordSubmit(username, code, password)
      .then(data => {
        logger.debug(data);
        // @ts-ignore
        setDelivery(null);
        history.replace("/");
        if (props.onStateChange) props.onStateChange("signIn");
      })
      .catch(err => {
        toast.error(err.message);
        console.log(err);
      });
    setLoader(false);
  };

  const submitView = () => {
    return (
      <Form onSubmit={handleSubmit(submit)} autoComplete="off">
        <input autoComplete="false" name="hidden" type="text" hidden></input>
        <Form.Field required>
          <label>Code</label>
          <input
            data-testid="CustomForgotPasswordCodeInput"
            type="text"
            name="code"
            autoComplete="false"
            style={{
              width: "100%",
              margin: "8px 0",
              display: "inline-block"
            }}
            placeholder={parsed.tempP ? parsed.tempP : "Enter your code"}
            ref={register}
            disabled={parsed.tempP ? true : false}
            value={parsed.tempP ? parsed.tempP : undefined}
            required
          />
        </Form.Field>

        <Form.Field required autoComplete="off">
          <label>New Password</label>
          <input
            data-testid="CustomForgotPasswordNewPasswordInput"
            type="password"
            name="newPassword"
            autoComplete="false"
            style={{
              width: "100%",
              margin: "8px 0",
              display: "inline-block"
            }}
            placeholder="Enter your new password"
            ref={register}
            required
          />
        </Form.Field>
        <div style={{ display: "flex" }}>
          <Button
            {...{ loading: loader }}
            style={{
              flex: 1,
              width: "20%",
              display: "inline-block",
              backgroundColor: "#78B8F0",
              color: "white",
              marginLeft: "auto",
              marginRight: "auto",
              fontSize: "medium"
            }}
            data-testid="CustomForgotPasswordNewPasswordSubmitButton"
            type="submit"
          >
            Save new password
          </Button>
        </div>
      </Form>
    );
  };

  return (
    <Grid
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        verticalAlign: "center",
        flexDirection: "column",
        flexWrap: "nowrap"
      }}
    >
      <Image
        src={Logo}
        style={{
          height: `100px`,
          width: `auto`,
          justifyContent: "flex-start",
          alignSelf: "center",
          marginTop: 30
        }}
      />
      <Grid.Column computer={6} tablet={8} mobile={14}>
        <Card fluid centered>
          <Card.Content>
            <Card.Header data-testid="CustomForgotPasswordHeader">
              Reset your password
            </Card.Header>
          </Card.Content>
          <Card.Content>
            <Form onSubmit={handleSubmit(send)}>
              <Form.Field required>
                <label>Email</label>
                <input
                  data-testid="CustomForgotPasswordEmailInput"
                  type="email"
                  name="email"
                  style={{
                    width: "100%",
                    margin: "8px 0",
                    display: "inline-block"
                  }}
                  placeholder={parsed.up ? parsed.up : "Enter your email"}
                  ref={register}
                  required
                  value={parsed.up ? parsed.up : undefined}
                  disabled={delivery || parsed.up ? true : false}
                />
              </Form.Field>
              {!delivery && !parsed.up ? (
                <div style={{ display: "flex" }}>
                  <Button
                    {...{ loading: loader }}
                    style={{
                      flex: 1,
                      width: "20%",
                      display: "inline-block",
                      backgroundColor: "#78B8F0",
                      color: "white",
                      marginLeft: "auto",
                      marginRight: "auto",
                      fontSize: "medium"
                    }}
                    data-testid="CustomForgotPasswordEmailSubmitButton"
                    type="submit"
                  >
                    Send Code
                  </Button>
                </div>
              ) : null}
            </Form>
            {delivery || parsed.tempP ? submitView() : null}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "10px"
              }}
            >
              <div
                data-testid="CustomForgotPasswordGoToSignInTag"
                style={{
                  color: "#0d1457",
                  fontSize: "14px",
                  cursor: "pointer"
                }}
                onClick={() => {
                  if (props.onStateChange) props.onStateChange("signIn");
                  history.replace("/");
                }}
              >
                Back to Sign In
              </div>
            </div>
          </Card.Content>
        </Card>
      </Grid.Column>
    </Grid>
  );
};

export default CustomForgotPassword;
