import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Auth from "@aws-amplify/auth";
import { Dimmer, Loader } from "semantic-ui-react";
const queryString = require("query-string");

const AutoConfirmInvitation = ({
  props
}: {
  props: {
    authData?: {
      attributes: {
        email: string;
        sub: string;
      };
    };
    authState?: string;
    onStateChange?: (state: string, data?: string) => any;
  };
}) => {
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  useEffect(() => {
    const autoSignInUser = async () => {
      if (parsed && parsed.up && parsed.pw) {
        let user = await Auth.signIn(parsed.up, parsed.pw);
        if (props.onStateChange)
          props.onStateChange("requireNewPassword", user);
      }
    };
    autoSignInUser();
  }, [props, props.onStateChange, parsed]);

  if (props.authState !== "requireNewPassword")
    return (
      <Dimmer active>
        <Loader size="massive">Fetching information ...</Loader>
      </Dimmer>
    );
  else return null;
};

export default AutoConfirmInvitation;
