import React, { useState, useEffect, useCallback, useRef } from "react";
import { Form, Card, Button, Grid, Image, Message } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { ConsoleLogger as Logger } from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import { toast } from "react-toastify";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { useHistory } from "react-router-dom";
import Logo from "../../assets/images/hubhighup-logo.png";

export interface ISignUpConfig {
  defaultCountryCode?: number | string;
  header?: string;
  hideAllDefaults?: boolean;
  hiddenDefaults?: string[];
  signUpFields?: ISignUpField[];
  usernameAttributes: string;
}
export interface SignUpParams {
  username: string;
  password: string;
  attributes?: {
    [key: string]: any;
  };
  validationData?: CognitoUserAttribute[];
  clientMetadata?: {
    [key: string]: string;
  };
}

export interface ISignUpField {
  label: string;
  key: string;
  placeholder?: string;
  required: boolean;
  displayOrder: number;
  invalid?: boolean;
  custom?: boolean;
  type?: string;
  "data-testid"?: string;
}

const logger = new Logger("SignUp");

const CustomSignUp = (props: {
  signUpConfig: ISignUpConfig;
  authData?: {
    attributes: {
      email: string;
      sub: string;
    };
  };
  authState?: string;
  onStateChange?: (state: string) => any;
}) => {
  const { register, handleSubmit } = useForm();
  let defaultSignUpFields = useRef<ISignUpField[]>();
  let signUpFields = useRef<ISignUpField[]>();
  const [loader, setLoader] = useState<boolean>(false);
  const history = useHistory();
  const notify = () => toast.success("The code was sent to your eMail");

  const checkCustomSignUpFields = useCallback(() => {
    return (
      props.signUpConfig &&
      props.signUpConfig.signUpFields &&
      props.signUpConfig.signUpFields.length > 0
    );
  }, [props.signUpConfig]);

  if (checkCustomSignUpFields()) {
    signUpFields.current = props.signUpConfig.signUpFields!;
  }
  const sortFields = useCallback(() => {
    if (
      props.signUpConfig &&
      props.signUpConfig.hiddenDefaults &&
      props.signUpConfig.hiddenDefaults.length > 0
    ) {
      if (defaultSignUpFields.current) {
        defaultSignUpFields.current = defaultSignUpFields.current.filter(d => {
          // @ts-ignore
          return !props.signUpConfig.hiddenDefaults.includes(d.key);
        });
      }
    }

    if (checkCustomSignUpFields()) {
      if (!props.signUpConfig || !props.signUpConfig.hideAllDefaults) {
        // see if fields passed to component should override defaults
        if (defaultSignUpFields.current) {
          defaultSignUpFields.current.forEach((f, i) => {
            if (signUpFields.current) {
              if (signUpFields.current) {
                const matchKey = signUpFields.current.findIndex(d => {
                  return d.key === f.key;
                });
                if (matchKey === -1) {
                  if (signUpFields.current) signUpFields.current.push(f);
                }
              }
            }
          });
        }
      }

      /* 
            sort fields based on following rules:
            1. Fields with displayOrder are sorted before those without displayOrder
            2. Fields with conflicting displayOrder are sorted alphabetically by key
            3. Fields without displayOrder are sorted alphabetically by key
          */
      if (signUpFields.current) {
        signUpFields.current.sort((a, b) => {
          if (a.displayOrder && b.displayOrder) {
            if (a.displayOrder < b.displayOrder) {
              return -1;
            } else if (a.displayOrder > b.displayOrder) {
              return 1;
            } else {
              if (a.key < b.key) {
                return -1;
              } else {
                return 1;
              }
            }
          } else if (!a.displayOrder && b.displayOrder) {
            return 1;
          } else if (a.displayOrder && !b.displayOrder) {
            return -1;
          } else if (!a.displayOrder && !b.displayOrder) {
            if (a.key < b.key) {
              return -1;
            } else {
              return 1;
            }
          } else return 0;
        });
      } else {
        signUpFields.current = defaultSignUpFields.current!;
      }
    }
  }, [checkCustomSignUpFields, props.signUpConfig]);
  useEffect(() => {
    sortFields();
  }, [sortFields]);

  const getUsernameLabel = () => {
    return props.signUpConfig.usernameAttributes;
  };

  const needPrefix = (key: any) => {
    if (signUpFields.current) {
      const field = signUpFields.current.find(e =>
        e && e.key ? e.key === key : null
      );
      if (key.indexOf("custom:") !== 0) {
        if (field) return field.custom;
      } else if (
        key.indexOf("custom:") === 0 && field ? field.custom === false : null
      ) {
        logger.warn(
          "Custom prefix prepended to key but custom field flag is set to false; retaining manually entered prefix"
        );
      }
    }
    return null;
  };

  const onSubmit = async (values: any) => {
    setLoader(true);
    console.log(loader);
    const signup_info: SignUpParams = {
      username: values.username,
      password: values.password,
      attributes: {},
      clientMetadata: {
        url: window.location.protocol + "//" + window.location.host,
        username: values.username
      }
    };
    const inputKeys = Object.keys(values);
    const inputVals = Object.values(values);

    inputKeys.forEach((key, index) => {
      if (
        // @ts-ignore
        !["username", "password", "checkedValue", "dial_code"].includes(key)
      ) {
        if (
          key !== "phone_line_number" &&
          key !== "dial_code" &&
          key !== "error"
        ) {
          const newKey: string = `${needPrefix(key) ? "custom:" : ""}${key}`;
          if (signup_info.attributes)
            signup_info.attributes[newKey] = inputVals[index];
        }
      }
    });

    let labelCheck = false;
    if (signUpFields.current)
      signUpFields.current.forEach(field => {
        if (field && field.label === getUsernameLabel()) {
          logger.debug(`Changing the username to the value of ${field.label}`);
          if (signup_info.attributes)
            signup_info.username =
              signup_info.attributes[field.key] || signup_info.username;
          labelCheck = true;
        }
      });
    if (!labelCheck && !signup_info.username) {
      // if the customer customized the username field in the sign up form
      // He needs to either set the key of that field to 'username'
      // Or make the label of the field the same as the 'usernameAttributes'
      throw new Error(
        `Couldn't find the label: ${getUsernameLabel()}, in sign up fields according to usernameAttributes!`
      );
    }
    await Auth.signUp(signup_info)
      .then(data => {
        // @ts-ignore
        props.onStateChange("confirmSignUp", data.user.username);
        notify();
      })
      .catch(err => {
        toast.error(err.message);
      });
    setLoader(false);
  };

  if (props.authState === "signUp") {
    return (
      <Grid
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          verticalAlign: "center",
          flexDirection: "column",
          flexWrap: "nowrap"
        }}
      >
        <Image
          src={Logo}
          style={{
            height: `100px`,
            width: `auto`,
            justifyContent: "flex-start",
            alignSelf: "center",
            marginTop: 30
          }}
        />
        <Grid.Column computer={6} tablet={8} mobile={14}>
          <Card centered fluid>
            <Card.Content>
              <Card.Header data-testid="CustomSignUpHeader">
                Create a new account for your company/organization
              </Card.Header>
            </Card.Content>
            <Card.Content>
              <Form onSubmit={handleSubmit(onSubmit)}>
                {props.signUpConfig.signUpFields
                  ? props.signUpConfig.signUpFields.map(field => {
                      const formField = (
                        <Form.Field required={field.required} key={field.key}>
                          <label>{field.label}</label>
                          <input
                            data-testid={field["data-testid"]}
                            type={field.type}
                            name={field.key}
                            key={field.key}
                            placeholder={field.placeholder || field.label}
                            ref={register}
                            required={field.required}
                          />
                        </Form.Field>
                      );
                      if (field.displayOrder === 1) {
                        return (
                          <>
                            {formField}
                            <div style={{ marginTop: 20 }}>
                              <div
                                style={{
                                  height: "0.5px",
                                  backgroundColor: "black",
                                  textAlign: "left",
                                  marginBottom: 5
                                }}
                              >
                                <span
                                  style={{
                                    backgroundColor: "white",
                                    position: "relative",
                                    top: "-0.7em",
                                    marginLeft: 15,
                                    padding: 5,
                                    fontSize: 13
                                  }}
                                >
                                  <b>Administrator</b>
                                </span>
                              </div>
                            </div>
                            <Message
                              content="You will be added as an administrator of the account for the newly registered company/organization. After you finish registration and login, you will be able to invite other people to join the account."
                              info
                            />
                          </>
                        );
                      } else if (field.displayOrder === 4) {
                        return (
                          <>
                            {formField}
                            <div style={{ paddingBottom: 10 }}>
                              Does your company already have an account? Please
                              ask the administrator to send you an invitation.
                            </div>
                          </>
                        );
                      }
                      return formField;
                    })
                  : null}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center"
                  }}
                >
                  <Button
                    {...{ loading: loader }}
                    style={{
                      flex: 1,
                      width: "20%",
                      display: "inline-block",
                      backgroundColor: "#78B8F0",
                      color: "white",
                      marginLeft: "auto",
                      marginRight: "auto",
                      fontSize: "medium"
                    }}
                    data-testid="CustomSignUpSubmitButton"
                    type="submit"
                  >
                    Create Account
                  </Button>
                </div>
              </Form>
              <div
                style={{
                  display: "flex",
                  fontSize: "14px",
                  justifyContent: "center",
                  paddingTop: "10px"
                }}
                onClick={() => {
                  if (props.onStateChange) props.onStateChange("signIn");
                }}
              >
                Already have an account ?
                <div
                  data-testid="CustomSignUpGoToSignInTag"
                  style={{
                    color: "#0d1457",
                    fontSize: "14px",
                    paddingLeft: "5px",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    if (props.onStateChange) props.onStateChange("signIn");
                    history.replace("");
                  }}
                >
                  Sign In
                </div>
              </div>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
    );
  } else return <div></div>;
};

export default CustomSignUp;
