import React, { useEffect } from "react";
import { useLocation } from "react-router";
import AutoConfirmInvitation from "./AutoConfirmInvitation";
import CustomSignIn from "./CustomSignIn";
import CustomForgotPassword from "./CustomForgotPassword";
import AutoConfirmSignUp from "./AutoConfirmSignUp";

const SignInRouter = (props: {
  authData?: {
    attributes: {
      email: string;
      sub: string;
    };
  };
  authState?: string;
  onStateChange?: (state: string, data?: string) => any;
}) => {
  const location = useLocation();
  const { authState, onStateChange } = props;

  useEffect(() => {
    if (authState !== "loading" && onStateChange) {
      if (authState !== "signUp" && location.search.includes("signup"))
        onStateChange("signUp");
      else if (authState !== "signIn" && location.search.includes("signin"))
        onStateChange("signIn");
    }
  }, [location, onStateChange, authState]);

  if (location.pathname === "/confirminvitation")
    return <AutoConfirmInvitation props={props} />;
  else if (
    location.pathname === "/resetpassword" ||
    props.authState === "forgotPassword"
  )
    return <CustomForgotPassword props={props} />;
  else if (location.pathname === "/confirmemail") {
    return <AutoConfirmSignUp props={props} />;
  } else return <CustomSignIn props={props} />;
};

export default SignInRouter;
