import React, { useState } from "react";
import { JS, ConsoleLogger as Logger } from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import { Card, Form, Button, Grid, Image } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Logo from "../../assets/images/hubhighup-logo.png";

const logger = new Logger("RequireNewPassword");

const CustomRequireNewPassword = (props: {
  authData?: {
    attributes: {
      email: string;
      sub: string;
    };
    challengeParam: any;
  };
  authState?: string;
  onStateChange?: (state: string, data?: string) => any;
}) => {
  const { register, handleSubmit } = useForm();
  let history = useHistory();
  const [loader, setLoader] = useState<boolean>(false);

  const checkContact = (user: any) => {
    if (!Auth || typeof Auth.verifiedContact !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported"
      );
    }
    Auth.verifiedContact(user).then(data => {
      if (!JS.isEmpty(data.verified)) {
        if (props.onStateChange) props.onStateChange("signedIn", user);
      } else {
        user = Object.assign(user, data);
        if (props.onStateChange) props.onStateChange("verifyContact", user);
      }
    });
  };

  const change = async (values: any) => {
    setLoader(true);
    const user = props.authData;
    if (user) {
      const { requiredAttributes } = user.challengeParam;

      const attrs = objectWithProperties(values, requiredAttributes);

      if (!Auth || typeof Auth.completeNewPassword !== "function") {
        throw new Error(
          "No Auth module found, please ensure @aws-amplify/auth is imported"
        );
      }

      await Auth.completeNewPassword(user, values.password, attrs, {
        url: window.location.protocol + "//" + window.location.host
      })
        .then(user => {
          history.replace("/");
          logger.debug("complete new password", user);
          if (user.challengeName === "SMS_MFA") {
            if (props.onStateChange) props.onStateChange("confirmSignIn", user);
          } else if (user.challengeName === "MFA_SETUP") {
            logger.debug("TOTP setup", user.challengeParam);
            if (props.onStateChange) props.onStateChange("TOTPSetup", user);
          } else {
            checkContact(user);
          }
        })
        .catch(err => console.log(err));
    }
    setLoader(false);
  };

  function objectWithProperties(obj: any, keys: any) {
    const target: {
      [key: string]: any;
    } = {};
    for (const key in obj) {
      if (keys.indexOf(key) === -1) {
        continue;
      }
      if (!Object.prototype.hasOwnProperty.call(obj, key)) {
        continue;
      }
      target[key] = obj[key];
    }
    return target;
  }
  if (props.authState === "requireNewPassword") {
    return (
      <Grid
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          verticalAlign: "center",
          flexDirection: "column",
          flexWrap: "nowrap"
        }}
      >
        <Image
          src={Logo}
          style={{
            height: `100px`,
            width: `auto`,
            justifyContent: "flex-start",
            alignSelf: "center",
            marginTop: 30
          }}
        />
        <Grid.Column computer={6} tablet={8} mobile={14}>
          <Card fluid centered>
            <Card.Content>
              <Card.Header data-testid="CustomRequireNewPasswordHeader">
                Change password
              </Card.Header>
            </Card.Content>
            <Card.Content>
              <Form onSubmit={handleSubmit(change)}>
                <Form.Field required>
                  <label>New password</label>
                  <input
                    data-testid="CustomRequireNewPasswordInput"
                    type="password"
                    name="password"
                    style={{
                      width: "100%",
                      margin: "8px 0",
                      display: "inline-block"
                    }}
                    placeholder="Enter your new password"
                    ref={register}
                    required
                  />
                </Form.Field>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center"
                  }}
                >
                  <Button
                    {...{ loading: loader }}
                    style={{
                      flex: 1,
                      width: "20%",
                      display: "inline-block",
                      backgroundColor: "#78B8F0",
                      color: "white",
                      marginLeft: "auto",
                      marginRight: "auto",
                      fontSize: "medium"
                    }}
                    data-testid="CustomRequireNewPasswordSubmitButton"
                    type="submit"
                  >
                    Change
                  </Button>
                </div>
              </Form>
              <div
                style={{
                  display: "flex",
                  fontSize: "14px",
                  justifyContent: "center",
                  paddingTop: "10px"
                }}
              >
                <div
                  data-testid="CustomRequireNewPasswordGoToSignInTag"
                  style={{
                    color: "#0d1457",
                    fontSize: "14px",
                    paddingLeft: "5px",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    if (props.onStateChange) props.onStateChange("signIn");
                  }}
                >
                  Back to Sign In
                </div>
              </div>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
    );
  } else return <div></div>;
};

export default CustomRequireNewPassword;
