/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:f5ac8ae3-18f8-4830-ba92-bd9c1b8253e7",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_PjWmDs5qc",
    "aws_user_pools_web_client_id": "vtio2sup12nqp11kkildr40uj",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://ab4cdpfyyzcmdek5tfbgifc624.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "qrcodeapi",
            "endpoint": "https://7kp46tu9l1.execute-api.ap-southeast-2.amazonaws.com/release",
            "region": "ap-southeast-2"
        },
        {
            "name": "users",
            "endpoint": "https://lmukdhqe5a.execute-api.ap-southeast-2.amazonaws.com/release",
            "region": "ap-southeast-2"
        },
        {
            "name": "testseed",
            "endpoint": "https://bh8mno5q4k.execute-api.ap-southeast-2.amazonaws.com/release",
            "region": "ap-southeast-2"
        },
        {
            "name": "gqlexport",
            "endpoint": "https://qr95z9k62b.execute-api.ap-southeast-2.amazonaws.com/release",
            "region": "ap-southeast-2"
        },
        {
            "name": "adminAPI",
            "endpoint": "https://3oxtwv1jc5.execute-api.ap-southeast-2.amazonaws.com/release",
            "region": "ap-southeast-2"
        }
    ],
    "aws_mobile_analytics_app_id": "f19b98190faf467ab11fe09ab7bc6c4e",
    "aws_mobile_analytics_app_region": "us-west-2",
    "aws_dynamodb_all_tables_region": "ap-southeast-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "distributedlock-release",
            "region": "ap-southeast-2"
        }
    ],
    "aws_content_delivery_bucket": "hubhighup-20200126120551-hostingbucket-release",
    "aws_content_delivery_bucket_region": "ap-southeast-2",
    "aws_content_delivery_url": "https://d1xael82movh3z.cloudfront.net"
};


export default awsmobile;
