import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Auth from "@aws-amplify/auth";
import { Loader } from "semantic-ui-react";
import { toast } from "react-toastify";
const queryString = require("query-string");

const AutoConfirmSignUp = ({
  props
}: {
  props: {
    authData?: {
      attributes: {
        email: string;
        sub: string;
      };
    };
    authState?: string;
    onStateChange?: (state: string, data?: string) => any;
  };
}) => {
  let history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  useEffect(() => {
    AutoSignInUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const AutoSignInUser = async () => {
    try {
      if (parsed && parsed.up && parsed.ccode) {
        let user = await Auth.confirmSignUp(parsed.up, parsed.ccode, {
          clientMetadata: {
            url: window.location.protocol + "//" + window.location.host
          }
        });
        history.replace("/");
        if (props.onStateChange)
          props.onStateChange("signInConfirmation", user);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      history.replace("/");
    }
  };

  if (location.pathname === "/confirmemail")
    return (
      <Loader size="massive" active={true}>
        Confirming SignUp...
      </Loader>
    );
  else return null;
};

export default AutoConfirmSignUp;
