import React, { useState } from "react";
import { ConsoleLogger as Logger } from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import { Card, Form, Button, Grid, Image } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Logo from "../../assets/images/hubhighup-logo.png";

const logger = new Logger("ConfirmSignUp");

const CustomConfirmSignUp = (props: {
  authData?: {
    attributes: {
      email: string;
      sub: string;
    };
  };
  authState?: string;
  onStateChange?: (state: string, data?: string) => any;
}) => {
  const { register, handleSubmit } = useForm();
  const [loader, setLoader] = useState<boolean>(false);

  const usernameFromAuthData = () => {
    const { authData } = props;
    if (!authData) {
      return "";
    }

    let username = "";
    if (typeof authData === "object") {
      // user object
      username = authData.attributes ? authData.attributes.email : "";
    } else {
      username = authData; // username string
    }

    return username;
  };

  const submitForm = async (values: any) => {
    setLoader(true);
    const username = usernameFromAuthData();
    const code = values.code;
    if (!Auth || typeof Auth.confirmSignUp !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported"
      );
    }

    await Auth.confirmSignUp(username, code, {
      clientMetadata: {
        url: window.location.protocol + "//" + window.location.host
      }
    })
      .then(() => {
        if (props.onStateChange) props.onStateChange("signInConfirmation");
      })
      .catch(err => toast.error(err.message));
    setLoader(false);
  };
  const resend = async () => {
    setLoader(true);
    const username = usernameFromAuthData();
    if (!Auth || typeof Auth.resendSignUp !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported"
      );
    }
    await Auth.resendSignUp(username, {
      url: window.location.protocol + "//" + window.location.host
    })
      .then(() => logger.debug("code resent"))
      .catch(err => toast.error(err.message));
    setLoader(false);
  };

  if (props.authState === "confirmSignUp") {
    const username = usernameFromAuthData();
    return (
      <Grid
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          verticalAlign: "center",
          flexDirection: "column",
          flexWrap: "nowrap"
        }}
      >
        <Image
          src={Logo}
          style={{
            height: `100px`,
            width: `auto`,
            justifyContent: "flex-start",
            alignSelf: "center",
            marginTop: 30
          }}
        />
        <Grid.Column computer={6} tablet={8} mobile={14}>
          <Card centered fluid>
            <Card.Content>
              <Card.Header data-testid="CustomConfirmSignUpHeader">
                Confirm Sign Up
              </Card.Header>
            </Card.Content>
            <Card.Content>
              <Form onSubmit={handleSubmit(submitForm)}>
                <Form.Field required>
                  <label>Email</label>
                  <input
                    data-testid="CustomConfirmSignUpEmailInput"
                    disabled={username ? true : false}
                    type="email"
                    name="email"
                    style={{
                      width: "100%",
                      margin: "8px 0",
                      display: "inline-block"
                    }}
                    value={username ? username : ""}
                    ref={register}
                    required
                  />
                </Form.Field>
                <Form.Field required>
                  <label>Confirmation Code</label>
                  <input
                    data-testid="CustomConfirmSignUpCodeInput"
                    type="text"
                    name="code"
                    style={{
                      width: "100%",
                      margin: "8px 0",
                      display: "inline-block"
                    }}
                    placeholder="Enter your code"
                    ref={register}
                    required
                  />
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <div style={{ marginRight: "5px", fontSize: "12px" }}>
                      Lost your code?
                    </div>
                    <div
                      data-testid="CustomConfirmSignUpResendCodeTag"
                      style={{
                        color: "#0d1457",
                        fontSize: "12px",
                        cursor: "pointer"
                      }}
                      onClick={() => resend()}
                    >
                      Resend code
                    </div>
                  </div>
                </Form.Field>
                <div style={{ display: "flex" }}>
                  <Button
                    {...{ loading: loader }}
                    style={{
                      flex: 1,
                      width: "20%",
                      display: "inline-block",
                      backgroundColor: "#78B8F0",
                      color: "white",
                      marginLeft: "auto",
                      marginRight: "auto",
                      fontSize: "medium"
                    }}
                    data-testid="CustomConfirmSignUpSubmitButton"
                    type="submit"
                  >
                    Confirm
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "10px"
                  }}
                >
                  <div
                    data-testid="CustomConfirmSignUpGoToSignInTag"
                    style={{
                      color: "#0d1457",
                      fontSize: "14px",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      if (props.onStateChange) props.onStateChange("signIn");
                    }}
                  >
                    Back to Sign In
                  </div>
                </div>
              </Form>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
    );
  } else {
    return null;
  }
};

export default CustomConfirmSignUp;
